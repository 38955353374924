import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';


export const RoadMap = () => {
    const info = [
        {
            title: "30.09",
            desc: <span>Public beta release<br/> Additional NFT collections<br/>  Regular crypto draws</span>,
            year: "2023", 
            classes: 'space-y-[60px] h-full flex flex-col left-[330px] absolute top-0 z-[20] lg:flex-row lg:left-[30px] lg:space-y-[0] lg:space-x-[40px] lg:top-[8%] lg:items-center lg:h-fit',
            isActive: true,
        },
        {
            title: "4th Q",
            desc: <span>Full public version release <br/> Game updates&patches <br/>Craft2earn integrations <br/>NFT marketplace</span>,
            year: "2023", 
            classes: 'space-y-[60px] space-y-reverse flex flex-col-reverse justify-start left-[605px] absolute top-[80px] z-[20] lg:flex-row-reverse lg:space-x-reverse lg:left-[70px] lg:space-y-[0] lg:space-x-[40px] lg:top-[28.5%] lg:items-center lg:h-fit',
            isActive: false,
        },
        {
            title: "1st-2nd Q",
            desc: <span>Release of the new Games <br/>Additional NFT collections<br/>More craft2earn<br/>Start of the clan system</span>,
            year: "2024", 
            classes: 'space-y-[30px] h-full flex flex-col left-[880px] absolute top-0 z-[20] lg:flex-row lg:left-[30px] lg:space-y-[0] lg:space-x-[40px] lg:top-[50.5%] lg:items-center lg:h-fit ',
            isActive: false,
        },
        {
            title: "3rd-4th Q",
            desc: <span>MEO coin drop&sale<br /> Play2earn system starts<br /> New games<br /> Additional NFT collections</span>,
            year: "2024", 
            classes: 'space-y-[60px] space-y-reverse flex flex-col-reverse left-[1150px] absolute top-[80px] z-[20]  lg:flex-row-reverse lg:space-x-reverse lg:left-[70px] lg:space-y-[0] lg:space-x-[40px] lg:top-[72%] lg:items-center lg:h-fit',
            isActive: false,
        },
    ]

    const [lenghtLastActive, setLenghtLastActive] = useState(0);
    
    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
          function updateSize() {
            
            setSize([window.innerWidth, window.innerHeight]);
          }
          window.addEventListener('resize', updateSize);
          updateSize();
          return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
      }


    const [width] = useWindowSize();

    useEffect(() => {
        findLastActive();
    }, [width]);

    const findLastActive = () => {
        let temp = info.slice().reverse();
        const findItem = temp.find((item) => !!item.isActive)

        let tempPosition = document.getElementById(findItem?.title);

        let tempPositionWrapper = document.getElementById('gradient');
        let positionWrapper = tempPositionWrapper?.getBoundingClientRect();

        if (!!tempPosition) {
            let position = tempPosition?.getBoundingClientRect();

            console.log(positionWrapper, position);

            if (width < 1121) {
                setLenghtLastActive((position?.y - positionWrapper?.y) + position?.height * 0.5);
            } else {
                setLenghtLastActive((position?.x - positionWrapper?.x) + position?.width * 0.5);
            }
           
        }
    }   

    console.log(width, lenghtLastActive);

    const styleGradient = useMemo(( ) => {
       if (width < 1121) {
        return { height: `${lenghtLastActive}px`};
       } else {
        return { width: `${lenghtLastActive}px`}
       }
        
    }, [lenghtLastActive]);
   
    return (
        <div className="py-12 sm:py-5 w-full h-full">
           <div className="flex flex-col items-center justify-center space-y-20 sm:space-y-10 h-full w-full overflow-auto   ">
                <span className="font-poppins text-white text-4xl font-semibold lg:pb-0">ROADMAP</span>
                <div className="flex justify-center items-start">
                <div className="h-full relative w-[1500px] overflow-x-auto overflow-y-hidden lg:overflow-x-hidden lg:w-fit ">
                        <div id={'gradient'} className="relative h-[450px] lg:h-[950px] lg:w-[375px] ">
                            <div style={styleGradient} className="z-[11] absolute shadow-roadmap top-0 left-0 h-full w-[20%] lg:w-full lg:h-[11%]" />
                            {/* <div className="z-[11] absolute shadow-roadmap top-0 left-0 h-full hidden sm:block w-full h-[11%]" /> */}
                            <div className="z-[0] absolute roadmap_point top-0 right-0 w-[100%] h-full" />
                            <img className='z-[20] absolute top-0 left-0 w-[1500px] h-[450px] lg:h-[950px] lg:hidden roadmap_shadow ' src="/images/roadmap/road.png"/>
                            <img className='z-[20] absolute top-0 left-0 w-full h-[950px] hidden lg:flex roadmap_shadow_mob' src="/images/roadmap/mobRoad.png"/>
                        </div>
                        {info.map((item, itemIndex) => {
                            return (
                            <div className={item.classes}>
                                <div className='flex flex-col space-y-3'>
                                    <div className="flex items-center justify-start space-x-3">
                                        <span className="font-poppins text-white text-xl lg:text-lg">{item.title}</span>
                                        <div  className="rounded-[30px] text-base bg-white-50">
                                            <span className='px-[10px] text-white-500 font-light lg:text-xs '>{item.year}</span>
                                        </div>
                                    </div>
                                    <span className="text-white-500 font-light leading-9 lg:text-sm">{item.desc}</span>
                                </div>

                                 {item.isActive ? <div id={item?.title} className="rounded-full w-full max-w-[155px] h-[155px] lg:w-[77px] lg:h-[77px]  flex items-center justify-center p-4 roadmap_point_activate lg:p-2">
                                                <div className="bg-[#141517] rounded-full w-full h-full flex items-center justify-center roadmap_point_activate_shadow">
                                                    <span className="text-[80px] font-bold roadmap_number_activate lg:text-[40px]">{itemIndex + 1}</span>
                                                </div>
                                            </div>: 
                                            <div  className="rounded-full w-full max-w-[155px] h-[155px] lg:w-[77px] lg:h-[77px] roadmap_point flex items-center justify-center">
                                                <span className="text-[80px] font-bold roadmap_number lg:text-[40px]">{itemIndex + 1}</span>
                                            </div>
                                }

                            </div>
                            )
                        })}
                </div>
                </div>
           </div>
        </div>
    )
}
