import React, { useState } from "react";

import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

export const FaqItem = ({ title, desc }) => {

  const [isActive, setIsActive] = useState(false);

  return (
    <div className={`${isActive ? 'faq_gradient' : 'bg-white-100'} p-[1px] rounded-[25px]`}>
                        <AccordionItem  className='border-[1px] border-white-100 bg-[#141517] rounded-[25px] w-full'>
                            <AccordionItemHeading className="w-full">
                                <AccordionItemButton  className="flex items-center justify-between ">
                                    <button onClick={() => setIsActive(!isActive)} className='flex items-center justify-between w-full space-x-2.5 px-8 py-6 sm:px-5 sm:py-5'>
                                        <span className='text-left text-xl font-medium font-poppins sm:text-sm '>{title}</span>
                                        <img className={`w-[24px] h-[24px] sm:w-[20px] sm:h-[20px] transition-all ease-out ${isActive ? 'rotate-45' :'rotate-0'}`}  src='/icons/plus.svg' />
                                    </button>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className='pb-5 px-8 sm:px-5'>
                                <span className='text-white-400 font-light sm:text-sm'>
                                  {desc}
                                </span>
                            </AccordionItemPanel>
                        </AccordionItem>
                        </div>
  )
}