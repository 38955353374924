import React, { useEffect, useMemo, useReducer, useState } from 'react';
import '../../App.css'
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { ActivateModal } from "../../components/Modals/ActivateModal";
import { useModal } from "../../helpers/hooks/useModal";
import { useWeb3React } from "@web3-react/core";
import { Timer } from '../../components';
import { isBefore } from 'date-fns';
import { INVITE_CODE_START } from '../../helpers/constants';
import { copy } from '../../helpers/text';

export const InviteCode = () => {
    const { openedModal, onOpen, onClose } = useModal()
    const {account} = useWeb3React();

    const [isCompletedCountdown, setIsCompletedCountdown] = useState(!isBefore(new Date(), new Date(INVITE_CODE_START)));

    const [code, setCode] = useState(null);
    const [loadingCode, setLoadingCode] = useState(true);

    const steps = [
        {
            text: 'Get the code',
            img: '/images/inviteCode/cardsSection/1.png',
        },
        {
            text: 'Give it to Your Friend',
            img: '/images/inviteCode/cardsSection/2.png',
        },
        {
            text: 'Let him put your Code at the Window in MEO World App',
            img: '/images/inviteCode/cardsSection/3.png',
        },
    ];

    const features = [
        {
            text: 'You Get 1500',
            img: '/images/inviteCode/person-1.png',
        },
        {
            text: 'Your Friend Get 1500',
            img: '/images/inviteCode/person-2.png',
        },
        {
            text: 'Every 3 Invites Give You a TICKET',
            img: '/images/inviteCode/person-3.png',
        },
    ];

    const renderGenerateCode = useMemo(() => {
        if (!isCompletedCountdown) {
            return (
                <div className="w-full h-full text-3xl font-semibold flex items-center justify-center text-white">
                    <Timer isCompletedCountdown={isCompletedCountdown} setIsCompletedCountdown={setIsCompletedCountdown} />
                </div>
            );
        }
        if (account) {
            if (loadingCode) {
                return (
                    <span className='z-[11] text-white text-xl font-semibold'>Loading code...</span>
                )
            } else {
                if (code !== 'no user') {
                    return (
                        <div className="relative flex items-center justify-center h-[134px] w-full bg-white-30 rounded-[20px] overflow-hidden">
                            <img className=' absolute top-0 w-full left-1/2 -translate-x-1/2' src="/images/inviteCode/code-shadow.png" alt="" />
                            <span className='z-[11] text-white text-[40px] font-semibold'>{code}</span>
                            <button className='z-[11] absolute top-1/2 -translate-y-1/2 right-10' onClick={() => copy(code)}><img className='h-[18px] w-[18px]' src="/icons/copy.svg" alt="" /></button>
                        </div>
                    )
                }
                return (
                    <div className="flex flex-col items-center text-center justify-center text-white space-y-1.5">
                        <span >You have to login</span>
                        <span>the MEO World app</span>
                        <span>to get the invite code</span>
                        <a className='!mt-5 text-white bg-[#5051F9] px-5 py-2.5 rounded-[100px]' href="https://play.google.com/store/apps/details?id=meo.world" target='_blank'>Download it at Google Play</a>
                    </div>
                )
            }
        } else {
            return  <button onClick={onOpen} className='text-white bg-[#5051F9] px-5 py-2.5 rounded-[100px]'>Connect wallet</button>
        }
    }, [isCompletedCountdown, account, code, loadingCode])

    const postCode = (wallet) => {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                setCode(this.responseText);
                setLoadingCode(false);
            }
        };
        xhttp.open("POST", "https://puma-nakama-prod.meo.world/v2/rpc/rpcGetReferalLinkByWallet?http_key=a6dk_hkj!j778&unwrap=", true);
        xhttp.send(wallet);
    }

    useEffect(() => {
        if (account) {
            postCode(account);
        }
    }, [account])

    console.log(code, loadingCode);

    return (
        <div className="bg-[#141517] overflow-hidden relative  min-h-screen flex flex-col justify-between">
            <img className="absolute h-full left-0 z-[0]" src="/images/collectionPage/top_shadow.png" />
            <img className="absolute h-full right-0 z-[0]" src="/images/collectionPage/bottom_shadow.png" />
            <Header />
            <div className="z-[10] w-full h-full flex flex-col items-center space-y-[30px] px-[15px] pb-[50px]">
                <span className='text-[#F0F0F0] text-[50px] font-bold sm:text-[20px] uppercase z-[1]'>MEO WORLD INVITE CODE</span>
                <div className="flex items-center justify-between space-x-[30px] max-w-[1020px] w-full sm:flex-col sm:space-x-0 sm:space-y-5">
                    {steps?.map((item, itemIndex) => {
                        return (
                            <div className=" w-full relative flex flex-col justify-end items-center bg-[#141517] rounded-[30px] border border-white-100 h-[325px] overflow-hidden pb-10" key={itemIndex}>
                                <div className="">
                                    <img className="z-[11] absolute w-full top-0 left-1/2 -translate-x-1/2" src={`/images/inviteCode/cardsSection/shadow-${itemIndex + 1}.png`} alt="" />
                                    <img className='z-[12] absolute top-0 left-1/2 -translate-x-1/2' src={item?.img} alt={`${itemIndex}_img`} /> 
                                </div>
                                
                                <span className='text-[#768394] text-center px-5 max-w-[280px] text-[18px]'>{item?.text}</span>
                            </div>
                        )
                    })}
                </div>
                <div className="relative z-[10] p-5 pb-[50px] flex sm:flex-col items-center justify-between space-x-[30px] sm:space-x-0 sm:space-y-5 max-w-[1020px] w-full bg-[#141517] sm:bg-inherit h-[365px] sm:h-auto rounded-[30px] border border-white-100 sm:border-none overflow-hidden">
                    <img className="z-[11] absolute top-0 left-1/2 -translate-x-1/2 sm:hidden" src='/images/inviteCode/person_shadow.png' alt="" />
                    {features?.map((item, itemIndex) => {
                        return (
                            <div className="z-[12] flex-1 w-full h-full flex flex-col sm:flex-row sm:space-x-5 justify-between items-center max-w-[280px] sm:max-w-full sm:w-full overflow-hidden" key={itemIndex}>
                                <div className="flex items-center justify-center max-h-[238px] sm:max-w-[145px] sm:w-full sm:max-h-[145px] h-full">
                                    <img className='max-h-full h-full' src={item?.img} alt={`${itemIndex}_img`} /> 
                                </div>
                               <div className="w-full flex justify-center items-center">
                                <span className={` text-[#768394] text-center px-5 sm:px-0 text-[18px] ${itemIndex === 2 && 'max-w-[200px] '}`}>{item?.text}</span>
                               </div>
                            </div>
                        )
                    })}
                </div>
                <span className='text-[#F0F0F0] text-[50px] font-bold uppercase sm:text-[20px] z-[1]'>Your invite code</span>
                <div className="z-[10] max-w-[462px] w-full flex flex-col justify-center items-center space-y-10 rounded-[30px] bg-white-30 p-10">
                    {renderGenerateCode}
                </div>
            </div>
           
            <ActivateModal handleCloseModal={onClose} openedModal={openedModal} />
            <Footer />
        </div>
    );
};
