import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermsConditions } from "./pages/TermsConditions";
import { MainPage } from "./pages/MainPage";
import { MyNft } from "./pages/MyNft";
import { DeleteRequestForm } from "./pages/DeleteRequestForm";
import { InviteCode } from "./pages/InviteCode";

export const RoutesFind = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<div><MainPage /></div>} />
                <Route path="/privacyPolicy" element={<div><PrivacyPolicy /></div>} />
                <Route path="/termsConditions" element={<div><TermsConditions /></div>} />
                <Route path="/myNft" element={<div><MyNft /></div>} />
                <Route path="/deleteRequestForm" element={<div><DeleteRequestForm /></div>} />
                <Route path="/inviteCode" element={<div><InviteCode /></div>} />
            </Routes>
        </Router>
    )
}