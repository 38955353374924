import React from 'react'

export const NFTrade = () => {

    const bgStyle = {
        backgroundImage: `url('/bg/blue_blur.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    };

    return (
        <div className="px-5 pb-10">
            <a href='https://nftrade.com/collection/meo-world' className="block flex flex-col justify-between overflow-hidden relative mr-auto ml-auto mt-[60px] sm:mt-[30px] w-full max-w-[1210px] min-h-[540px] sm:min-h-fit rounded-[30px] border-solid border-[1px] border-white-100 px-[60px] py-[40px] sm:px-[0] sm:w-full sm:px-[20px] sm:py-[40px] sm:flex sm:flex-col sm:items-center">
                <div style={bgStyle} className="z-0 absolute top-0 left-0 right-0 w-full h-full" />
                <div className="z-[40] flex flex-col sm:items-center mt-[60px] sm:mt-[0]">
                    <div className="z-[40] block w-full max-w-[330px] text-5xl text-[#F0F0F0] font-bold sm:text-xl sm:max-w-[300px] sm:flex sm:justify-center">MEO WORLD ON NFTRADE</div>
                    <div className="z-[40] block mt-[60px] bg-white hover:opacity-50 rounded-[50px] w-full max-w-[265px] h-[64px] flex items-center justify-center cursor-pointer sm:mt-[40px]">
                        <img src="/icons/nftrade.svg" alt="nftrade logo" />
                    </div>
                </div>
                <img className="z-[10] absolute w-[179px] top-[34%] right-[50%] sm:hidden" src="/images/nftradeMobile.png" alt="NFTrade mobile image" />
                <img className="z-[10] absolute w-[566px] top-[13%]  right-0 sm:hidden" src="/images/nftradeDesktop.png" alt="NFTrade desktop image" />
                <div className="z-[20] flex flex-row  justify-around sm:flex-wrap sm:mt-[30px] sm:px-3">
                    <div className="z-[20] flex flex-col space-y-[8px] items-center sm:order-0 p-1.5 ">
                        <span className="z-10 text-[32px] text-white font-bold sm:text-[18px]">$2.77k</span>
                        <span className="text-[16px] text-white opacity-50 sm:text-[12px] font-light">total sales volume</span>
                    </div>
                    <div className="z-[20] flex flex-col space-y-[8px] items-center sm:order-2 p-1.5">
                        <span className="z-10 text-[32px] text-white font-bold sm:text-[18px]">0.027 BNB</span>
                        <span className="text-[16px] text-white opacity-50 sm:text-[12px] font-light">floor price</span>
                    </div>
                    <div className="z-[20] flex flex-col space-y-[8px] items-center sm:order-1 p-1.5">
                        <span className="z-10 text-[32px] text-white font-bold sm:text-[18px]">724</span>
                        <span className="text-[16px] text-white opacity-50 sm:text-[12px] font-light">listed</span>
                    </div>
                    <div className="z-[20] flex flex-col space-y-[8px] items-center sm:order-3 p-1.5">
                        <span className="z-10 text-[32px] text-white font-bold sm:text-[18px]">197.64k</span>
                        <span className="text-[16px] text-white opacity-50 sm:text-[12px] font-light">owners</span>
                    </div>
                    <div className="z-[20] flex flex-col space-y-[8px] items-center sm:order-5 p-1.5">
                        <span className="z-10 text-[32px] text-white font-bold sm:text-[18px]">243.64k</span>
                        <span className="text-[16px] text-white opacity-50 sm:text-[12px] font-light">Items</span>
                    </div>
                </div>
            </a>
        </div>
    )
}
