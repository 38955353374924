import React from 'react'

export const NftCollection = () => {

    return (

        <div className="px-5 pb-10 ">
            <a href='/myNft' className="flex overflow-hidden relative mr-auto ml-auto mt-[60px] sm:mt-[30px] w-full max-w-[1210px] min-h-[580px] sm:min-h-fit rounded-[30px] border-solid border-[1px] border-white-100 px-[60px] sm:w-full sm:px-[20px] sm:py-[20px] sm:flex sm:flex-col sm:items-center sm:jusitfy-center sm:min-h-[300px] space-y-[40px]">
                <img className="absolute h-full left-0 sm:hidden" src="/images/nftcollection/blue_shadow.png" />
                <img className="absolute w-[500px] right-0 sm:hidden" src="/images/nftcollection/green_shadow.png" />
                <img className="absolute right-[200px] h-[550px] bottom-0 sm:hidden"  src="/images/nftcollection/red_shadow.png" />
                <div className="z-[40] flex flex-col justify-center items-start sm:justify-between sm:items-center sm:h-full">
                    <div className="font-poppins z-[40] block w-full max-w-[400px] text-5xl uppercase text-[#F0F0F0] font-semibold sm:text-xl sm:max-w-[300px] sm:flex sm:justify-center">Your MEO NFT Collection</div>
                    <div className="z-[40] block mt-[20px] max-w-[350px] font-light text-base text-white-300 sm:text-sm sm:max-w-[300px] sm:text-center"><span>A cozy place where your cougars await until you come to enjoy them.</span></div>
                    <a href="/MyNft" className="z-[40] block mt-[60px] px-5 py-2 bg-[#5051F9] hover:opacity-50 rounded-full flex items-center justify-center cursor-pointer sm:w-full sm:py-4">
                        <span className="font-poppins text-base text-white">Browse collection</span>
                    </a>
                </div>
                <div className='flex z-[0] items-center sm:hidden  '>
                    <img className="w-[400px] right-[21%] absolute top-[200px]" src="/images/nftcollection/redNft.png" />
                    <img className="w-[400px] right-0 absolute right-[4%] top-[40px]" src="/images/nftcollection/greenNft.png" />
                </div>
                <div className="hidden sm:flex w-full items-center justify-center">
                    <img src="/images/nftcollection/mob_nfts.png"/>
                </div>
             </a>
        </div>

    )
}