import React from "react";

export const DeleteRequestForm = () => {
  
    return (
        <div className="bg-[#141517] overflow-hidden h-full flex flex-col items-center py-16 overflow-auto space-y-14 sm:w-full ">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc6UXQqER09eP_xy8sNK7ai-W3UKGNdcggNdbMAfVJSSjFrwA/viewform?embedded=true" width="640" height="586" frameborder="0" marginheight="0" marginwidth="0">Загрузка…</iframe>
        </div>
    )

} 