import React, { useState } from 'react';

export const SwiperWrapper = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const maxImages = 4;

    const onPrevClick = () => {
        if (currentImage > 0) setCurrentImage(prev => prev - 1)
        if (currentImage === 0) setCurrentImage(maxImages - 1)
    }

    const onNextClick = () => {
        if (currentImage === maxImages - 1) setCurrentImage(0)
        if (currentImage < maxImages - 1) setCurrentImage(prev => prev + 1)
    }

    return (
        <>
        <div className="flex flex-col items-center z-[50] space-y-[30px] sm:space-y-2.5">
           <div className="flex flex-row z-[50]">
               <img src="/icons/arrowSwiperLeft.svg" className="mr-[35px] cursor-pointer sm:hidden" onClick={() => onPrevClick()} alt="on prev slide" />
               <div className="w-[1020px] h-[480px] sm:max-w-[90vw] sm:h-auto overflow-hidden rounded-[30px] overflow-hidden boorder border-solid border-white-100">
                   <img src={`images/carousel/${currentImage}.png`} alt="main image" />
               </div>
               <img src="/icons/arrowSwiperRight.svg" className="ml-[35px] cursor-pointer sm:hidden" onClick={() => onNextClick()} alt="on next slide" />
           </div>
            <div className="flex flex-row space-x-5 z-[50] sm:space-x-1.5 sm:max-w-[90vw]">
                {new Array(maxImages).fill({}).map((item, index) => {
                const isActive = index === currentImage;
                return (
                    <div key={index} onClick={() => setCurrentImage(index)} className={`cursor-pointer w-[188px] h-[120px] sm:flex-1 sm:w-auto sm:h-auto rounded-[20px] overflow-hidden border border-solid border-white-100 ${isActive ? 'border-2 border-[#2AFE9F]' : ''}`}>
                        <img src={`/images/carousel/${index}.png`} alt="item slide" />
                    </div>
                )    
                })}
            </div>
        </div>
        {/* <div className="hidden sm:flex sm:gap-[20px] z-[50]">
            {new Array(maxImages).fill({}).map((item, index) => (
                    <div key={index} className="w-[300px] min-w-[300px] h-[200px] rounded-[20px] overflow-hidden border border-solid border-white-100 z-[50]">
                        <img className="w-[300px] min-w-[300px] h-[200px]" src={`images/carousel/${index}.png`} alt="item slide mobile" />
                    </div>
                ))}
        </div> */}
        </>
    );
};
