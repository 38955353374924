import React from "react";
import { NFTrade, RoadMap, SwiperWrapper } from "../../features";
import '../../App.css'
import { Winners } from "../../features/Winners";
import { Faq } from "../../features/Faq";
import { NftCollection } from "../../features/NftCollection"
import { Header } from "../../components/header";


export const MainPage = () => {
    const wrapperStyle = {
        backgroundImage: `url('/bg/bg_picture.png')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat;',
        backgroundPosition: 'start',
        backgroundAttachment: 'absolute',
      };
    return (
    <div className="bg-[#141517] overflow-hidden">
      <div
        style={wrapperStyle}
        className="relative w-screen flex flex-col text-darkGrey lg:p-1.5 h-full sm:min-h-[350px] pb-[100px] sm:pb-0"
      >
        <div>
       <Header />

        <div className='flex items-center justify-center h-full flex-col pt-[40px] sm:pt-[5px]'>
          <div className="flex items-center flex-col space-y-[35px]">
            <p className='text-white text-5xl font-bold sm:text-xl text-center whitespace-nowrap font-poppins'>WELCOME TO MEO WORLD</p>
            <p className='text-white opacity-50 font-light text-center sm:hidden'>A Play-to-Earn game with NFT as a battle pass. <br /> Train your coguars and earn crypto!</p>
          </div>
          <img className='z-10 w-[1150px] top-[100px] flex items-center justify-center py-[65px] sm:py-[40px]' src="/MEO-WORLD.png"/>
        </div>
        </div>
        <div className='absolute bottom-0 left-0 right-0 shadowbox-background w-full h-[40%]' />
      </div>
     
          <div className="flex items-center justify-center pt-[100px] relative sm:pt-[40px] flex-col sm:w-full sm:px-[20px] mb-20">
            <img className="z-[111] lg:z-[30] absolute h-[120vh] sm:h-[50vh] left-0 sm:-bottom-10" src="/images/yellowTiger.png" />
            {/* <p className='text-7xl font-bold text-center pt-[50px] pb-[40px] sm:pt-0 text-white sm:text-3xl z-[40] firstWin-button-gradient font-poppins'>BETA IS LIVE</p> */}
            <div className='z-[40] sm:px-2.5 flex flex-col items-center text-center max-w-[600px] text-white-500 mb-[40px] space-y-5 text-md'>
              <span> MEO WORLD is a <span className='text-white font-semibold'>mobile game</span> that includes an <span className='text-white font-semibold'>NFT</span> battle pass and <span className='text-white font-semibold'>a P2E coin</span>, which is deployed in both the blockchain and the Game as in-game currency.</span>
              <span> In a galaxy far away, <span className='text-white font-semibold'>cougars</span> developed advanced technology and created <span className='text-white font-semibold'>Millennium Games</span> to train themselves and become more and more advanced.</span>
              <span> Each cougar is <span className='text-white font-semibold'>unique</span>, as it comes with a distinctive set of characteristics. </span>
              <span className='text-white font-semibold'> Collect them, upgrade them, compete with other players, and earn crypto!</span>
            </div>
            <div className="z-[1111]  flex space-x-[30px] sm:flex-col sm:space-x-0 sm:space-y-[20px] sm:w-full ">
              <div className="flex bg-black-150  rounded-[10px] items-center py-[20px] px-[20px] z-[30] backdrop-blur-xl  ">
                <div className='z-[30] flex justify-between items-center w-full space-x-[50px]'>
                  <p className="text-white">iOS App</p>
                  <div className='flex items-center space-x-[20px] '>
                    <p className='text-white opacity-70'>Coming soon </p>
                    <img src="/icons/appleIcon.svg" />
                  </div>
                </div>
              </div>
              <a href='https://play.google.com/store/apps/details?id=meo.world' target="_blank" className="roadmap_point_activate flex bg-black-150 rounded-[10px] items-center py-[20px] px-[20px] z-[1]">
                <div className='z-20 flex justify-between items-center w-full space-x-[50px]'>
                  <p className="text-black-800 font-bold">Android App</p>
                  <div className='flex items-center space-x-[20px]'>
                    <p className='text-black-800 font-bold opacity-70'></p>
                    <img src="/icons/androidIcon.svg" />
                  </div>
                </div>
              </a>
            </div>
            <img className="z-[111] sm:z-[30] absolute h-[120vh] sm:h-[40vh] right-0 sm:-top-[120px]" src="/images/purpleTiger.png" />
          </div>
          <RoadMap />
          <div className="relative ml-auto flex flex-col items-center mr-auto mt-[150px] mb-[100px] sm:mt-[30px] sm:mb-[50px] space-y-[30px] px-5">
            <div className="text-[#F0F0F0] text-[50px] font-bold sm:text-[20px] z-[1]">
              EXPLORE THE SHATTERED
            </div>
            <iframe
                className="z-[40] rounded-[30px] w-full max-w-[1210px] h-[720px] lg:max-w-[840px] lg:h-[500px] z-[1] sm:h-[220px] sm:max-w-[90vw] sm:w-full lg:px-4 sm:px-0"
                type="text/html"
                src="https://www.youtube.com/embed/y8VF5V8SlaY?autoplay=0&loop=1&modestbranding=1&fs=0&rel=0&showinfo=0&mute=0&controls=1"
              />
            <img className="absolute right-0 top-[-200px] lg:hidden " src="/images/shattered/right_planet.png"/>
            <img className="absolute left-5 bottom-0  lg:hidden" src="/images/shattered/left_planet.png"/>
            <img className="absolute right-0 z-[30]  lg:hidden" src="/images/shattered/right_shadow.png"/>
            <img className="absolute left-0 z-[30] lg:hidden" src="/images/shattered/left_bot_shadow.png"/>
            <img className="absolute left-0 z-[30] top-[-600px] lg:hidden" src="/images/shattered/left_top_shadow.png"/>
          </div>
          <SwiperWrapper />
          <NftCollection />
          {/* <form id="contact-form" className="py-[200px] flex items-center justify-center flex-col space-y-[35px] sm:py-[50px] sm:px-[20px] sm:pt-[100px] sm:space-y-[25px] ">
            <p className='text-white text-5xl font-bold text-center sm:text-xl uppercase'>Subscribe to our newsletter <br/> to stay up to date with latest news </p>
            <p className='text-[#768394] opacity-50 font-light text-center'> The game will be launched soon! Be among the first to dive <br /> into the fascinating game world and start an exciting journey. </p>
            <div className='flex sm:flex-col sm:hidden sm:space-y-[10px]'>
              <input type="email" name="user_email" placeholder='Type your email here' className="bg-[#1F2022] rounded-l-full px-6 py-5 text-white placeholder-white-200 focus:outline-none"/>
              <div className="bg-[#1F2022] px-3  rounded-r-full flex items-center">
                <input type="submit" value="Send" className="rounded-full bg-[#5051F9] p-3 text-white font-medium">
                </input>

              </div>
            </div>
          </form> */}

          <NFTrade />     
          <Faq/>
          
          {/* <div className="flex mt-[260px] sm:mt-[60px] flex-col items-center">
            <div className="text-[#F0F0F0] text-[50px] font-bold sm:text-[20px]">
              LATEST NEWS & ARTICLES
            </div>
            <div className="flex mt-[60px] sm:mt-[40px] flex-row gap-[30px] sm:gap-[20px] sm:max-w-[336px] sm:overflow-scroll">
              <LastNewsCard />
              <LastNewsCard />
              <LastNewsCard />
            </div>
          </div> */}
          
          <div className="relative z-[10] mt-[25px] pb-[50px] sm:pb-[20px] px-5">
            <div className="relative z-[10] bg-[#1F2022] w-full rounded-[30px] flex justify-between sm:flex-col-reverse sm:bg-transparent px-[50px]">
              <p className="text-white sm:text-white-500 py-[30px] sm:text-sm sm:flex sm:flex-col sm:items-center "><p>MEO WORLD ©️ 2023</p> <p>ALL RIGHT RESERVED</p></p>
              <div className="flex items-center justify-center sm:w-full">
                <a className='cursor-pointer p-2.5' target='_blank' href='https://t.me/meoworldgame' rel="noreferrer">
                  <img src="/icons/telegram.svg" />
                </a>
                <a className='cursor-pointer p-2.5' target='_blank' href='https://www.youtube.com/@meoworldgame/featured' rel="noreferrer">
                  <img src="/icons/youtube.svg" />
                </a>
                <a className='cursor-pointer p-2.5' target='_blank' href='https://discord.gg/meoworld' rel="noreferrer">
                  <img src="/icons/discord.svg" />
                </a>
                <a className='cursor-pointer p-2.5' target='_blank' href='https://twitter.com/meoworldgame' rel="noreferrer">
                  <img src="/icons/twitter.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>

    );
};