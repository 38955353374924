import React from 'react'
import {
    Accordion,
} from 'react-accessible-accordion';
import { FaqItem } from './item';

import 'react-accessible-accordion/dist/fancy-example.css';

export const Faq = () => {

    

    const info = [
        {
            title: 'Where can I buy or sell NFTs?',
            desc: <><span>You can always buy or sell your NFTs on official marketplaces</span>&nbsp;<a className="text-white hover:underline" target='_blank' href="https://opensea.io/collection/meoworldnft" rel="noreferrer">Opensea,</a>&nbsp;<a className="text-white hover:underline" target='_blank' href="https://www.coingecko.com/en/nft/puma-labs-nft" rel="noreferrer">Coingecko,</a>&nbsp;<a className="text-white hover:underline" target='_blank' href="https://nftrade.com/collection/puma-nft" rel="noreferrer">NFTs trade</a></>,
        },
        {
            title: 'What is the price of NFT?',
            desc: <><span>You can always check the current prices for NFT by yourself on the official marketplaces</span>&nbsp;<a className="text-white hover:underline" target='_blank' href="https://opensea.io/collection/meoworldnft" rel="noreferrer">Opensea,</a>&nbsp;<a className="text-white hover:underline" target='_blank' href="https://www.coingecko.com/en/nft/puma-labs-nft" rel="noreferrer">Coingecko,</a>&nbsp;<a className="text-white hover:underline" target='_blank' href="https://nftrade.com/collection/puma-nft" rel="noreferrer">NFTs trade</a></>,
        },
        {
            title: 'What should I do with my NFT?',
            desc: 'Your NFT, in addition to their value as unique collectibles, are also a pass to a Play2Earn mobile game. Only NFT holders can play the game, so we advise not to rush to sell them.',
        },
        {
            title: 'Why is my NFT taking so long to sell?',
            desc: 'The marketplace where you sell your NFT works in the same way as a regular marketplace. As soon as an interested buyer is found, your NFT will be sold immediately. You can influence the speed of this process only by the uniqueness of your offer and the price of the item put up for sale.',
        },
        {
            title: 'When can I start earning in the game?',
            desc: 'At the first stage of the game there will be the Craft To Earn mechanics. This is required for players to get used to the game world and understand how everything works there. Play To Earn mechanics will be implemented later, when additional functionality will be unlocked.',
        },
        {
            title: 'Will there be new NFT collections?',
            desc: 'Yes, MEO plans to release additional NFT collections and special game-related collections. There are also plans to issue МЕО token as well. Please wait for the MEO Roadmap to be released to explore the development stages in more detail.',
        },
        {
            title: 'If additional NFTs are released, will the NFTs from the first collection become less valuable?',
            desc: 'The economics of new issues are designed so that additional collections will only increase the value of the initial Game Pass - cougars from the Meo World collection.',
        },
        {
            title: 'Where will the player payout funds come from to make the Play To Earn mechanic work?',
            desc: ' Based on the experience of other P2E games, it is possible to create a sustainable economic model based on the funds coming in from in-game purchases. A part of the revenue is allocated to pay players who spend more time on the game, or achieve higher scores. We carefully work out the balance to build a truly sustainable system with earning opportunities.',
        },
    ]


    return (

            <div className="flex flex-col items-center justify-center py-[100px] sm:py-10 text-white space-y-6 w-full sm:px-5">
                <span className="text-5xl text-white font-semibold ">FAQ</span>
                <div className="flex flex-col items-center justify-center text-white space-y-5 w-full max-w-[1210px]">
                <Accordion allowZeroExpanded allowMultipleExpanded className="w-full max-w-[1210px] space-y-5 ">
                    {info.map((item, itemIndex) => {
                        return <FaqItem {...item} key={itemIndex} />
                    })}
                </Accordion>
            </div>
            </div>

    )
}