import React, { useMemo, useEffect } from 'react';
import '../../App.css'
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { ActivateModal } from "../../components/Modals/ActivateModal";
import { useModal } from "../../helpers/hooks/useModal";
import { useWeb3React } from "@web3-react/core";
import { NftRepository } from "../../connectors/repositories/nft";
import { useRequest } from "../../helpers/hooks/useRequest";
import { RARITY_COLOR } from '../../helpers/nft';


export const MyNft = () => {
    const { openedModal, onOpen, onClose } = useModal()
    const {account} = useWeb3React();

    console.log(account, 'account')

    const onConnectWalletClick = () => {
        onOpen();
    };

    const { call, data, isLoading } = useRequest(NftRepository.getListNft, [{ owner_address: account }]); 

    useEffect(() => {
        if (account) {
            call();
        }
    }, [account]);

    console.log(data);

    const renderContent = useMemo(() => {
        if (!account) {
            return (
                    <div className="z-[111]">
                        <img className="absolute w-[600px] right-0 sm:hidden " src="/images/collectionPage/green_shadow.png" />
                        <img className="absolute w-[900px] right-0 sm:hidden " src="/images/collectionPage/red_shadow.png" />
                        <div className="pb-10 relative ">
                                <div className="flex overflow-hidden mr-auto ml-auto sm:mt-[30px] w-full max-w-[1300px] px-[100px] min-h-[580px] sm:min-h-full  sm:w-full sm:px-[20px] sm:py-[40px] sm:flex-col sm:items-center sm:space-y-[50px]">
                                    <div className="z-[40] flex flex-col justify-center items-start sm:items-center sm:w-full">
                                        <div className="font-poppins z-[40] max-w-[450px] block text-5xl uppercase text-[#F0F0F0] font-semibold sm:text-3xl sm:max-w-[300px] sm:flex sm:justify-center sm:text-center">See your MEO NFT collection </div>
                                        <div className="z-[40] block mt-[20px] font-light text-base text-white-300 lg:text-sm lg:max-w-[300px] sm:text-center"><span>Connect your wallet to enjoy your NFT cougars directly from your dashboard.</span></div>
                                        <button  onClick={() => {
                                            onConnectWalletClick() 
                                        }} className="z-[111] block mt-[20px] px-5 py-2 bg-[#5051F9] hover:opacity-50 rounded-full flex items-center justify-center cursor-pointer sm:w-full sm:py-4">
                                            <span className="font-poppins text-base text-white sm:text-sm">Connect wallet</span>
                                        </button>
                                    </div>
                                    <div className='flex z-[0] items-center sm:hidden '>
                                        <img className="w-[400px] right-[330px] absolute top-[170px]" src="/images/nftcollection/redNft.png" />
                                        <img className="w-[400px] right-0 absolute right-[130px] top-0" src="/images/nftcollection/greenNft.png" />
                                    </div>
                                    <div className="hidden sm:flex w-[90%]">
                                        <img src="/images/nftcollection/mob_nfts.png"/>
                                    </div>
                                </div>
                        </div>
                    </div>
      );
    } else {
        if (data?.nfts?.length && !isLoading) {
            return (
                <div className="relative ">
    
                        <div className="flex flex-col overflow-hidden mr-auto ml-auto  w-full max-w-[85vw] px-[100px] lg:min-h-fit lg:max-w-[100vw] lg:px-[20px] lg:py-[40px] lg:flex lg:flex-col lg:items-center space-y-[30px] lg:mt-[30px]">
                            <div className="flex flex-col space-y-2.5">
                                <div className="font-poppins z-[40] block text-5xl uppercase text-[#F0F0F0] font-semibold lg:text-xl lg:max-w-[300px] lg:flex lg:justify-center"><span className="leading-3">My MEO NFT ({data?.nfts?.length})</span></div>
                                {account && <span className='text-white font-semibold'>Wallet - {account.substring(0, 6)}...{account.substring(42 - 4)}</span>}
                                <span className="text-sm text-white  z-[1111] font-light text-white-500 sm:text-center sm:text-xs">  MEO World cougars come in five rarity types — Common, Uncommon, Rare, Epic, and Legendary. Rarity type affects the power and performance of your cougars in various games and activities on the platform. Do you have all rarity types in your collection? You can always add new cougars to your pack by purchasing them at NFT marketplaces.</span>
                            </div>
                            <div className="w-full h-full flex flex-wrap justify-center">
                          
                                {!!data?.nfts && data?.nfts?.map((item, itemIndex) => {
                                    const rarity = item?.attributes?.find(item => item?.trait_type === 'rarity')?.value;

                                        return (
                                            <div className="p-5 lg:p-2.5">
                                                <div className="flex flex-col justify-between w-[280px] space-y-[20px] bg-white-30 rounded-[30px] p-3"  key={itemIndex} >
                                                    <div className="w-full bg-white-40 rounded-[20px]">
                                                        <img className="rounded-[20px]" src={item?.image}/> 
                                                    </div>
                                                    <div className="flex justify-between w-full h-[28px]">
                                                        {/* <div className="bg-white-40 max-w-[115px] w-full h-[28px] rounded-[35px]">
                                                        </div> */}
                                                        <span className="font-poppins text-[16px] font-medium text-white">Meo #{item?.id}</span>
                                                        <div className={`${RARITY_COLOR[rarity]} max-w-[92px] w-full rounded-[35px] flex items-center justify-center`} >
                                                            <span className="capitalize text-white font-poppins font-light text-xs">{rarity}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between w-full bg-white-40 h-full max-h-[55px] rounded-[20px] py-2 px-5 ">
                                                        <div className="flex flex-col space-y-1 justify-center">
                                                            <span className="text-[12px] text-[#768394]">Power</span>
                                                            <span className="text-white text-xs">{item?.power}/100</span>
                                                        </div>
                                                        <div className="flex flex-col space-y-0.5 justify-center">
                                                            <span className="text-[12px] text-[#768394]">Address</span>
                                                            <a
                                                                href={item?.scan_url}
                                                                target="_blank"
                                                                className="cursor-pointer hover:underline flex items-center justify-end space-x-1"
                                                                rel="noreferrer"
                                                            >
                                                                <span className="text-xs text-white">bscscan</span>
                                                            
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        
                    </div>    
            );
        } else {
            return (
                <div className="flex flex-col overflow-hidden mr-auto ml-auto lg:mt-[30px] w-full max-w-[1300px] px-[100px] min-h-[580px] lg:min-h-fit  lg:w-full lg:px-[20px] lg:py-[40px] lg:flex lg:flex-col lg:items-center space-y-[70px]">
                    <div className="font-poppins z-[40] block text-5xl uppercase text-[#F0F0F0] font-semibold lg:text-xl lg:max-w-[300px] lg:flex lg:justify-center sm:upprecase"><span className="leading-3">Your collection</span></div>
                    <div className="relative flex items-center justify-center sm:w-full">
                        <img className="sm:hidden" src="/images/nftcollection/withoutNFT.png"/>
                        <img className="hidden sm:flex w-full" src="/images/nftcollection/withoutNFTMob.png"/>
                        <div className="absolute flex flex-col items-center justify-center space-y-6 sm:w-full sm:px-7">
                            <span className="text-2xl text-white z-[1111] font-poppins font-medium sm:text-lg">No NFT yet</span>
                            <span className="text-base text-white z-[1111] font-light text-white-500 text-center">Oops, it seems that you don’t have any NFT yet. How about adopting a couple of cougars at the NFT marketplace?</span>
                            <a target='_blank' href="https://nftrade.com/collection/meo-world" className="z-[111] block mt-[20px] px-5 py-2 bg-[#5051F9] hover:opacity-50 rounded-full flex items-center justify-center cursor-pointer sm:w-full sm:py-4">
                                <span className="font-poppins text-base text-white sm:text-sm">Buy NFT</span>
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    }
}, [account, data, isLoading]);

    return (
        <div className="bg-[#141517] overflow-hidden relative  min-h-screen flex flex-col justify-between">
            <img className="absolute h-full left-0 z-[0]" src="/images/collectionPage/top_shadow.png" />
            <img className="absolute h-full right-0 z-[0]" src="/images/collectionPage/bottom_shadow.png" />
            <Header />
            <div className="">
            {renderContent}
            </div>
           
            <ActivateModal handleCloseModal={onClose} openedModal={openedModal} />
            <Footer />
        </div>
    );
};
