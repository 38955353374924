import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

 
export const Header = () => {

  const location = useLocation();
  const info = [
    {
      title: "Home",
      href: '/',
    },
    {
      title: "NFT",
      href: '/myNft',
    },
    {
      title: "Gaming",
      href: '/inviteCode',
    },
  ]
    return (
        <div className="relative flex flex-col text-darkGrey lg:p-1.5 h-full sm:min-h-full sm:pb-0">
        <div>
          <div className='px-[100px] py-[35px] flex  items-center justify-between sm:px-[12px] sm:py-[16px]'>
            <img className='w-[180px] sm:w-[98px]' src="/logoMeo.png"/>
            <div className='text-white space-x-12 md:space-x-5'>
            {info.map((item, itemIndex) => {
              const isActive = location.pathname === item?.href;
               return (
                  <a href={item?.href} key={itemIndex} className={`${isActive ? 'text-[#2AFE9F]' : 'text-white'}`}>{item?.title}</a>
              )
            })}
            </div>
            <button className='border border-1 py-4 px-7 rounded-full opacity-50 sm:hidden'>
              <p className='text-[#F0F0F0] '>COMING SOON</p>
            </button>
          </div>
        </div>
      </div>

    )
}