import React from 'react'

export const Footer = () => {
    return (
        <div className="relative z-[10] mt-[25px] pb-[50px] sm:pb-[20px] px-5">
        <div className="relative z-[10] bg-[#1F2022] w-full rounded-[30px] flex justify-between sm:flex-col-reverse sm:bg-transparent px-[50px]">
          <p className="text-white sm:text-white-500 py-[30px] sm:text-sm sm:flex sm:flex-col sm:items-center "><p>MEO WORLD ©️ 2023</p> <p>ALL RIGHT RESERVED</p></p>
          <div className="flex items-center justify-center sm:w-full">
            <a className='cursor-pointer p-2.5' target='_blank' href='https://t.me/meoworldgame' rel="noreferrer">
              <img src="/icons/telegram.svg" />
            </a>
            <a className='cursor-pointer p-2.5' target='_blank' href='https://www.youtube.com/@meoworldgame/featured' rel="noreferrer">
              <img src="/icons/youtube.svg" />
            </a>
            <a className='cursor-pointer p-2.5' target='_blank' href='https://discord.gg/meoworld' rel="noreferrer">
              <img src="/icons/discord.svg" />
            </a>
            <a className='cursor-pointer p-2.5' target='_blank' href='https://twitter.com/meoworldgame' rel="noreferrer">
              <img src="/icons/twitter.svg" />
            </a>
          </div>
        </div>
      </div>
    )
}