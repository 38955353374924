export const RARITY_NAMES = {
  COMMON: 'common',
  UNCOMMON: 'uncommon',
  RARE: 'rare',
  EPIC: 'epic',
  LEGENDARY: 'legendary',
};

export const RARITY_COLOR = {
  [RARITY_NAMES.COMMON]: 'bg-white-100',
  [RARITY_NAMES?.UNCOMMON]: 'bg-uncommonNft',
  [RARITY_NAMES?.RARE]: 'bg-rareNft',
  [RARITY_NAMES?.EPIC]: 'bg-epicNft',
  [RARITY_NAMES?.LEGENDARY]: 'bg-legendaryNft',
};
