const BUSD_CONTRACT_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const NFT_LOOTBOX_ABI = [
  {
     "anonymous":false,
     "inputs":[
        {
           "indexed":true,
           "internalType":"address",
           "name":"owner",
           "type":"address"
        },
        {
           "indexed":true,
           "internalType":"address",
           "name":"approved",
           "type":"address"
        },
        {
           "indexed":true,
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"Approval",
     "type":"event"
  },
  {
     "anonymous":false,
     "inputs":[
        {
           "indexed":true,
           "internalType":"address",
           "name":"owner",
           "type":"address"
        },
        {
           "indexed":true,
           "internalType":"address",
           "name":"operator",
           "type":"address"
        },
        {
           "indexed":false,
           "internalType":"bool",
           "name":"approved",
           "type":"bool"
        }
     ],
     "name":"ApprovalForAll",
     "type":"event"
  },
  {
     "anonymous":false,
     "inputs":[
        {
           "indexed":false,
           "internalType":"address",
           "name":"operator",
           "type":"address"
        },
        {
           "indexed":false,
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        },
        {
           "indexed":false,
           "internalType":"uint256[]",
           "name":"params",
           "type":"uint256[]"
        }
     ],
     "name":"NewToken",
     "type":"event"
  },
  {
     "anonymous":false,
     "inputs":[
        {
           "indexed":true,
           "internalType":"address",
           "name":"from",
           "type":"address"
        },
        {
           "indexed":true,
           "internalType":"address",
           "name":"to",
           "type":"address"
        },
        {
           "indexed":true,
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"Transfer",
     "type":"event"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"to",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"approve",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"owner",
           "type":"address"
        }
     ],
     "name":"balanceOf",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"baseURI",
     "outputs":[
        {
           "internalType":"string",
           "name":"",
           "type":"string"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"contractOwner",
     "outputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"getApproved",
     "outputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"impl",
     "outputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"string",
           "name":"name",
           "type":"string"
        },
        {
           "internalType":"string",
           "name":"symbol",
           "type":"string"
        },
        {
           "internalType":"string",
           "name":"baseTokenURI",
           "type":"string"
        }
     ],
     "name":"init",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"owner",
           "type":"address"
        },
        {
           "internalType":"address",
           "name":"operator",
           "type":"address"
        }
     ],
     "name":"isApprovedForAll",
     "outputs":[
        {
           "internalType":"bool",
           "name":"",
           "type":"bool"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"lastTokenId",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"to",
           "type":"address"
        },
        {
           "internalType":"uint256[]",
           "name":"params",
           "type":"uint256[]"
        }
     ],
     "name":"mint",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"name",
     "outputs":[
        {
           "internalType":"string",
           "name":"",
           "type":"string"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "name":"operators",
     "outputs":[
        {
           "internalType":"bool",
           "name":"",
           "type":"bool"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"ownerOf",
     "outputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"from",
           "type":"address"
        },
        {
           "internalType":"address",
           "name":"to",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"safeTransferFrom",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"from",
           "type":"address"
        },
        {
           "internalType":"address",
           "name":"to",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        },
        {
           "internalType":"bytes",
           "name":"_data",
           "type":"bytes"
        }
     ],
     "name":"safeTransferFrom",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"operator",
           "type":"address"
        },
        {
           "internalType":"bool",
           "name":"approved",
           "type":"bool"
        }
     ],
     "name":"setApprovalForAll",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address[]",
           "name":"_operators",
           "type":"address[]"
        },
        {
           "internalType":"bool[]",
           "name":"_statuses",
           "type":"bool[]"
        }
     ],
     "name":"setOperators",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"bytes4",
           "name":"interfaceId",
           "type":"bytes4"
        }
     ],
     "name":"supportsInterface",
     "outputs":[
        {
           "internalType":"bool",
           "name":"",
           "type":"bool"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"symbol",
     "outputs":[
        {
           "internalType":"string",
           "name":"",
           "type":"string"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"index",
           "type":"uint256"
        }
     ],
     "name":"tokenByIndex",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"owner",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"index",
           "type":"uint256"
        }
     ],
     "name":"tokenOfOwnerByIndex",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"tokenURI",
     "outputs":[
        {
           "internalType":"string",
           "name":"",
           "type":"string"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"totalSupply",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"from",
           "type":"address"
        },
        {
           "internalType":"address",
           "name":"to",
           "type":"address"
        },
        {
           "internalType":"uint256",
           "name":"tokenId",
           "type":"uint256"
        }
     ],
     "name":"transferFrom",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  }
];

const XBASE_ABI = [
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "address",
      },
    ],
    name: "balances",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "lastUserId",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "userIds",
    outputs: [
      {
        name: "",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "owner",
    outputs: [
      {
        name: "",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "address",
      },
    ],
    name: "users",
    outputs: [
      {
        name: "id",
        type: "uint256",
      },
      {
        name: "referrer",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "uint8",
      },
    ],
    name: "levelPrice",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        name: "ownerAddress",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    payable: true,
    stateMutability: "payable",
    type: "fallback",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        name: "referrer",
        type: "address",
      },
    ],
    name: "Registration",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        name: "currentReferrer",
        type: "address",
      },
      {
        indexed: false,
        name: "matrix",
        type: "uint8",
      },
      {
        indexed: false,
        name: "level",
        type: "uint8",
      },
    ],
    name: "Reinvest",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        name: "referrer",
        type: "address",
      },
      {
        indexed: false,
        name: "matrix",
        type: "uint8",
      },
      {
        indexed: false,
        name: "level",
        type: "uint8",
      },
    ],
    name: "Upgrade",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        name: "referrer",
        type: "address",
      },
      {
        indexed: false,
        name: "matrix",
        type: "uint8",
      },
      {
        indexed: false,
        name: "level",
        type: "uint8",
      },
      {
        indexed: false,
        name: "place",
        type: "uint8",
      },
    ],
    name: "NewUserPlace",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        name: "referrer",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "MoneyHolded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        name: "referrer",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "MoneyUnholded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        name: "matrix",
        type: "uint8",
      },
      {
        indexed: false,
        name: "level",
        type: "uint8",
      },
    ],
    name: "MatixClosed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        name: "money",
        type: "uint256",
      },
    ],
    name: "CannotSendMoney",
    type: "event",
  },
  {
    constant: false,
    inputs: [
      {
        name: "referrerAddress",
        type: "address",
      },
    ],
    name: "registrationExt",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "matrix",
        type: "uint8",
      },
      {
        name: "level",
        type: "uint8",
      },
    ],
    name: "buyNewLevel",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "userAddress",
        type: "address",
      },
      {
        name: "level",
        type: "uint8",
      },
    ],
    name: "findFreeX3Referrer",
    outputs: [
      {
        name: "",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "userAddress",
        type: "address",
      },
      {
        name: "level",
        type: "uint8",
      },
    ],
    name: "findFreeX6Referrer",
    outputs: [
      {
        name: "",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "userAddress",
        type: "address",
      },
      {
        name: "level",
        type: "uint8",
      },
    ],
    name: "usersActiveX3Levels",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "userAddress",
        type: "address",
      },
      {
        name: "level",
        type: "uint8",
      },
    ],
    name: "usersActiveX6Levels",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "userAddress",
        type: "address",
      },
      {
        name: "level",
        type: "uint8",
      },
    ],
    name: "usersX3Matrix",
    outputs: [
      {
        name: "",
        type: "address",
      },
      {
        name: "",
        type: "address[]",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "userAddress",
        type: "address",
      },
      {
        name: "level",
        type: "uint8",
      },
    ],
    name: "usersX6Matrix",
    outputs: [
      {
        name: "",
        type: "address",
      },
      {
        name: "",
        type: "address[]",
      },
      {
        name: "",
        type: "address[]",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "user",
        type: "address",
      },
    ],
    name: "isUserExists",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "userAddress",
        type: "address",
      },
    ],
    name: "getActiveX3Levels",
    outputs: [
      {
        name: "res",
        type: "bool[12]",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "userAddress",
        type: "address",
      },
    ],
    name: "getActiveX6Levels",
    outputs: [
      {
        name: "res",
        type: "bool[12]",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

export default {
  allowedChainId: 56,
  stand: 'prod',

  NFTLootBoxAddress: '0xbfe3578a8f7fa99a2386bf8af8f26956abc409f7',
  NFTLootBoxAbi: NFT_LOOTBOX_ABI,

  BUSDContract: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  BUSDContractAbi: BUSD_CONTRACT_ABI,

  XBASEContract: '0x5acc84a3e955bdd76467d3348077d003f00ffb97',
  XBASEContractAbi: XBASE_ABI,
};
